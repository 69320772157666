<script setup lang="ts">
interface Props {
  firstName: string
  lastName: string
  srcAvatar?: string | null
  phone?: string | null
  email?: string | null
  isOwner?: boolean
  isAmbassador?: boolean | null
  isIdCertified?: boolean
  isPhoneCertified?: boolean
  isCarRegistrationCertified?: boolean
  isMotCertified?: boolean
  bookingCount?: number
  reviewCount?: number
  reviewAverage?: number
  showUserDetail?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  srcAvatar: null,
  phone: null,
  email: null,
  isOwner: false,
  isAmbassador: false,
  isIdCertified: false,
  isPhoneCertified: false,
  isCarRegistrationCertified: false,
  isMotCertified: false,
  bookingCount: 0,
  reviewCount: 0,
  reviewAverage: 0,
  showUserDetail: false,
})

defineEmits<{
  'show-reviews': []
}>()

/*
  * When a booking is in a state before CONFIRMED, API sometimes returns "******"
  * instead of user phone / email
  */
const isContactHidden = computed(() => {
  return props.phone && props.phone.match(/\*/g) !== null
})

const bookingCountThreshold = computed(() => {
  if (props.bookingCount < 10) {
    return props.bookingCount
  }

  if (props.bookingCount < 20) {
    return 10
  }

  if (props.bookingCount < 50) {
    return 20
  }

  if (props.bookingCount < 100) {
    return 50
  }

  return 100
})

const { t } = useI18n()
const certifiedInfos = computed(() => {
  const infos = []
  if (props.isIdCertified) {
    infos.push({ value: 0, text: t('data.user.id_certified.label') })
  }
  if (props.isPhoneCertified) {
    infos.push({ value: 1, text: t('data.user.phone_certified.label') })
  }
  if (props.isOwner && props.isCarRegistrationCertified) {
    infos.push({ value: 2, text: t('commons.car_registration_certified') })
  }
  if (props.isOwner && props.isMotCertified) {
    infos.push({ value: 3, text: t('commons.mot_certified') })
  }

  return infos
})
</script>

<template>
  <div class="flex space-x-4">
    <div>
      <YscAvatar
        :first-name="props.firstName"
        :picture="props.srcAvatar"
        :is-best-owner="props.isAmbassador"
        :is-verified="props.isIdCertified"
        radius="40"
      />
    </div>

    <div>
      <div class="flex flex-col">
        <span v-if="props.isOwner && props.isAmbassador">
          {{ $t('data.user.is_ambassador.label') }}
        </span>
        <span class="text-lg font-semibold"> {{ props.firstName }} {{ props.lastName }} </span>
      </div>
      <button
        v-if="props.showUserDetail && props.reviewCount"
        class="link flex items-center"
        @click="$emit('show-reviews')"
      >
        <YscIconsStarSolid class="mr-1 inline h-4 w-4 text-pink-500" />
        {{ props.reviewAverage }}
        ({{ $t('commons.reviews_count_plural', { count_number: $n(props.reviewCount) }, props.reviewCount) }})
      </button>

      <div
        v-if="props.email || props.phone"
        class="mt-2"
      >
        <a
          v-if="props.email"
          :href="`mailto:${props.email}`"
          class="link block leading-6"
        >
          {{ props.email }}
        </a>

        <a
          v-if="props.phone"
          :href="`tel:${props.phone}`"
          class="link block leading-6"
        >
          {{ props.phone }}
        </a>
      </div>

      <div
        v-if="bookingCountThreshold || props.reviewCount"
        class="mt-2"
      >
        <div v-if="bookingCountThreshold">
          <i18n-t
            keypath="components.app_user_card.transaction_dynamic"
            tag="p"
            scope="global"
          >
            <template #transaction_count_string>
              <span class="font-semibold">
                <template v-if="props.isOwner">
                  {{
                    $t('components.app_user_card.owner.renting_count_plural', {
                      count_number: $n(bookingCountThreshold),
                    }, bookingCountThreshold)
                  }}
                </template>
                <template v-else>
                  {{
                    $t('components.app_user_card.guest.booking_count_plural', {
                      count_number: $n(bookingCountThreshold),
                    }, bookingCountThreshold)
                  }}
                </template>
              </span>
            </template>
            <template #transaction_done_string>
              <template v-if="props.isOwner">
                {{ $t('components.app_user_card.renting_done_plural', bookingCountThreshold) }}
              </template>
              <template v-else>
                {{ $t('components.app_user_card.travel_done_plural', bookingCountThreshold) }}
              </template>
            </template>
          </i18n-t>
        </div>
      </div>

      <div
        v-if="certifiedInfos.length"
        class="mt-2"
      >
        <div
          v-for="info in certifiedInfos"
          :key="info.value"
          class="flex items-center space-x-2"
        >
          <YscIconsCheckBold class="h-2.5 w-2.5 text-pink-500" />
          <span>{{ info.text }}</span>
        </div>
      </div>

      <p
        v-if="props.showUserDetail && (isContactHidden || !(props.phone && props.email))"
        class="mt-4 text-gray-500"
      >
        <template v-if="props.isOwner">
          {{ $t('components.app_user_card.owner.infos_if_confirmed') }}
        </template>
        <template v-else>
          {{ $t('components.app_user_card.guest.infos_if_confirmed') }}
        </template>
      </p>
    </div>
  </div>
</template>
